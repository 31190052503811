import React, { useEffect, useRef, useState, useContext } from "react";
import ProductCard from "../components/ProductCard";
import { Link, useParams, useHistory } from "react-router-dom";
import { SERVER_URL } from "../config";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import HelmetMetaData from "../components/HelmetMetaData";
import FacebookSharingButton from "../components/FacebookSharingButton";
import TwitterSharingButton from "../components/TwitterSharingButton";
import LinkedinSharingButton from "../components/LinkedinSharingButton";
import WhatsappSharingButton from "../components/WhatsappSharingButton";

import { CustomerContext } from "../Routes";
import ReactImageMagnify from "react-image-magnify";
import Header from "../layouts/Header";
import Rating from "react-rating";
import Loader from "../components/Loader";
import Slider from "react-slick";
import { relatedProductSliderSetting } from "../helpers/slider";
import ReactImageZoom from "react-image-zoom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PageNotFound from "../components/PageNotFound";
import NewsLater from "../components/NewsLater";

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = false;
  document.body.appendChild(script);
}

const ProductDetails = () => {
  const { state, dispatch } = useContext(CustomerContext);
  const { cart } = state;

  const image = document.querySelector(".any-class-name img");
  if (image) {
    image.addEventListener("click", () => {
      alert("we");
    });
  }

  const { slug } = useParams();
  const history = useHistory();

  const [addWishlistLoading, setAddWishlistLoading] = useState(false);
  const [removeWishlistLoading, setRemoveWishlistLoading] = useState(false);
  const [addedToWishlist, setAddedToWishlist] = useState(false);
  const [myWishlists, setMyWishlists] = useState([]);
  const [removeFromWishlist, setRemoveFromWishlist] = useState(false);
  const [submitEnquiryLoading, setSubmitEnquiryLoading] = useState(false);
  const [contactDetails, setContactDetails] = useState({});
  const [productLoading, setProductLoading] = useState(true);
  const [sliderSettings, setSliderSettings] = useState({
    ...relatedProductSliderSetting,
  });
  const [product, setProduct] = useState({
    images: [],
    newArrivals: [],
    colors: [],
    reviews: [],
    bulletPoints: [],
    material: {},
    brand: {},
    description: "",
  });

  const [relatedProductLoading, setRelatedProductLoading] = useState(true);
  const [relatedProduct, setRelatedProduct] = useState([]);

  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));

  const ref = useRef(null);
  const reviewRef = useRef(null);
  const [formData, setFormData] = useState({
    name: undefined,
    mobile: undefined,
    city: undefined,
    email: undefined,
    message: undefined,
    product_slug: slug,
  });

  const [previewImage, setPreviewImage] = useState("");
  const [reviewAdded, setReviewAdded] = useState(true);
  const [addReviewLoading, setAddReviewLoading] = useState(false);
  const [avarageRating, setAvarageRating] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const [reviewStars, setReviewStars] = useState({
    five: 0,
    four: 0,
    three: 0,
    two: 0,
    one: 0,
  });
  const [review, setReview] = useState({
    rating: 0,
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  // Get Products
  useEffect(() => {
    // setProductLoading(true);
    fetch(`${SERVER_URL}/products?slug=${slug}`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setProduct(data.body[0] || {});

          // setPreviewImage(data.body.default_image);

          // Calculate avarage reviews
          const sumOfRatings = data.body[0].reviews.reduce(
            (accumulator, current) => {
              return accumulator + current.rating;
            },
            0
          );
          setAvarageRating(
            (sumOfRatings / data.body[0].reviews.length).toFixed(1)
          );

          // Calculate number of stars
          const stars = {
            five: 0,
            four: 0,
            three: 0,
            two: 0,
            one: 0,
          };
          data.body[0].reviews.forEach((item) => {
            switch (item.rating) {
              case 5:
                stars.five = stars.five + 1;
                break;
              case 4:
                stars.four = stars.four + 1;
                break;
              case 3:
                stars.three = stars.three + 1;
                break;
              case 2:
                stars.two = stars.two + 1;
                break;
              case 1:
                stars.one = stars.one + 1;
                break;
            }
          });

          setReviewStars({ ...stars });
        } else {
          toast.warning(data.message);
        }
        setProductLoading(false);

        AddLibrary("/assets/js/vendors.js");
        AddLibrary("/assets/js/active.js");
        // console.log("Product:", data);
      })
      .catch((error) => {
        setProductLoading(false);
        toast.warning(error);
      });
  }, [slug, reviewAdded]);

  // Get Related Products
  useEffect(() => {
    setRelatedProductLoading(true);
    if (product.childCategory && product.childCategory._id) {
      fetch(
        `${SERVER_URL}/products?childCategoryId=${product.childCategory._id}&limit=6`,
        {
          method: "GET", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            if (data.body.length > 4) {
              setSliderSettings((old) => {
                return { ...old, infinite: true };
              });
            } else {
              setSliderSettings((old) => {
                return { ...old, infinite: false };
              });
            }
            setRelatedProduct(data.body || []);
          } else {
            toast.warning(data.message);
          }
          setRelatedProductLoading(false);
          // console.log("Product:", data);
        })
        .catch((error) => {
          setRelatedProductLoading(false);
          toast.warning(error);
        });
    } else {
      setRelatedProductLoading(false);
    }
  }, [product]);

  // Get Wishlists
  useEffect(() => {
    // setProductLoading(true);
    fetch(`${SERVER_URL}/wishlists/myWishlist`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setMyWishlists(data.body || []);
        } else {
          // toast.warning(data.message);
        }
      })
      .catch((error) => {
        // setProductLoading(false);
        toast.warning(error);
      });
  }, [addedToWishlist, removeFromWishlist]);

  // Add Product to Wishlist
  const addWishlistHandler = (evt) => {
    evt.preventDefault();

    // check customer is logedIn or not
    if (state && state.jwtToken) {
      setAddWishlistLoading(true);
      fetch(`${SERVER_URL}/wishlists`, {
        method: "POST", // or 'PUT'
        body: JSON.stringify({ product: product._id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAddWishlistLoading(false);
          if (data.status == 200) {
            setAddedToWishlist(!addedToWishlist);
            toast.success(data.message);
          } else {
            toast.warning(data.message);
          }
          // console.log("Success:", data);
        })
        .catch((error) => {
          setAddWishlistLoading(false);
          toast.error(error);
        });
    } else {
      toast.warning("Please Login First");
      history.push("/account/login");
    }
  };

  // Remove Product to Wishlist
  const removeWishlistHandler = (evt, wishListId) => {
    evt.preventDefault();
    setRemoveWishlistLoading(true);
    fetch(`${SERVER_URL}/wishlists/${wishListId}`, {
      method: "DELETE", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRemoveWishlistLoading(false);
        if (data.status == 200) {
          toast.success(data.message);
          setRemoveFromWishlist(!removeFromWishlist);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setRemoveWishlistLoading(false);
        toast.error(error);
      });
  };

  const addToCartHandler = async (fromWhere, product, quantity) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        name: product.name,
        slug: product.slug,
        price: product.sellingPrice,
        sku: product.sku,
        weight: product.weight,
        length: product["length"],
        width: product.width,
        height: product.height,
        diameter: product.diameter,
        capacity: product.capacity,
        packageContent: product.packageContent,
        pileHeight: product.pileHeight,
        mountingType: product.mountingType,
        brand: product.brand._id,
        care: product.care,
        color: product.color,
        description: product.description,
        make: product.make,
        material: product.material._id,
        shape: product.shape,
        isReturnable: product.isReturnable,
        returnCondition: product.returnCondition,
        parentCategory: product.parentCategory._id,
        subCategory: product.subCategory._id,
        childCategory: product.childCategory._id,
        productId: product._id,
        quantity: quantity || 1,
        image: product.defaultImage,
        maxOrderQuantity: product.maxOrderQuantity,
      },
    });

    // add to database
    const response = await fetch(`${SERVER_URL}/cartProducts`, {
      body: JSON.stringify({ product: product._id }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();

    if (fromWhere == "BUY_NOW") {
      history.push("/checkout");
    }
  };

  const addReviewHandler = (evt) => {
    const updateData = {
      ...review,
      product: product._id,
    };

    evt.preventDefault();
    setAddReviewLoading(true);
    fetch(`${SERVER_URL}/productReviews`, {
      method: "POST",
      body: JSON.stringify(updateData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setAddReviewLoading(false);

          if (result.status == 200) {
            setReviewAdded(!reviewAdded);
            setReview({
              message: "",
              rating: 0,
            });
            toast.success(result.message);
          } else {
            const errors = result.errors;
            const keys = Object.keys(errors);
            for (let k of keys) {
              toast.error(errors[k]);
            }
            toast.error(result.message);
            // console.log(result);
          }
        },
        (error) => {
          setAddReviewLoading(false);
          toast.error(error);
          console.log(error);
        }
      );
  };

  const scrollToReview = (evt) => {
    evt.preventDefault();
    reviewRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    const link = document.createElement("link");
    if (product) {
      link.rel = "canonical";
      link.href = `https://www.ferraindica.com/product/${product.slug}`;
      document.head.appendChild(link);
    }

    return () => {
      // Cleanup in case the component is unmounted
      document.head.removeChild(link);
    };
  }, [product]);

  return (
    <>
      <Header />
      {!productLoading ? (
        <HelmetMetaData
          quotes=""
          title={product?.metaTitle || product.name}
          image={product.defaultImage}
          description={product?.metaDescription || product.name}
          hhashtag="#ferraIndica"
        />
      ) : (
        ""
      )}
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area section-space--half">
        <div className="container wide">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  breadcrumb wrpapper  =======*/}
              <div className="breadcrumb-wrapper breadcrumb-bg">
                {/*=======  breadcrumb content  =======*/}
                <div className="breadcrumb-content">
                  <h2 className="breadcrumb-content__title">Single Product</h2>
                  <ul className="breadcrumb-content__page-map">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li className="active">{product.name}</li>
                  </ul>
                </div>
                {/*=======  End of breadcrumb content  =======*/}
              </div>
              {/*=======  End of breadcrumb wrpapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  {/*=======  single product main content area  =======*/}
                  <div className="single-product-main-content-area section-space">
                    {productLoading ? (
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Spinner />
                          <Spinner />
                          <Spinner />
                        </div>
                      </div>
                    ) : (
                      <>
                        {product.name ? (
                          <div className="row">
                            <div className="col-lg-6">
                              {/*=======  product details slider area  =======*/}
                              <div className="product-details-slider-area">
                                <div className="big-image-wrapper">
                                  <div
                                    className="product-details-big-image-slider-wrapper product-details-big-image-slider-wrapper--bottom-space ht-slick-slider"
                                    data-slick-setting='{
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "arrows": false,
            "autoplay": false,
            "autoplaySpeed": 5000,
            "fade": true,
            "speed": 500,
            "prevArrow": {"buttonClass": "slick-prev", "iconClass": "fa fa-angle-left" },
            "nextArrow": {"buttonClass": "slick-next", "iconClass": "fa fa-angle-right" }
        }'
                                    data-slick-responsive='[
            {"breakpoint":1501, "settings": {"slidesToShow": 1, "arrows": false} },
            {"breakpoint":1199, "settings": {"slidesToShow": 1, "arrows": false} },
            {"breakpoint":991, "settings": {"slidesToShow": 1, "arrows": false, "slidesToScroll": 1} },
            {"breakpoint":767, "settings": {"slidesToShow": 1, "arrows": false, "slidesToScroll": 1} },
            {"breakpoint":575, "settings": {"slidesToShow": 1, "arrows": false, "slidesToScroll": 1} },
            {"breakpoint":479, "settings": {"slidesToShow": 1, "arrows": false, "slidesToScroll": 1} }
        ]'
                                  >
                                    {product.defaultImage ? (
                                      <div className="any-class-name">
                                        <ReactImageZoom
                                          {...{
                                            img: product.defaultImage,
                                            width: 600,
                                            height: 600,
                                            zoomWidth: 600,
                                            zoomPosition: "original",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {product.images.map((img, indx) => {
                                      return (
                                        <div
                                          key={indx}
                                          className="any-class-name"
                                        >
                                          {img.url ? (
                                            <ReactImageZoom
                                              {...{
                                                img: img.url,
                                                width: 600,
                                                height: 600,
                                                zoomWidth: 600,
                                                zoomPosition: "original",
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>

                                <div
                                  className="product-details-small-image-slider-wrapper product-details-small-image-slider-wrapper--horizontal-space ht-slick-slider"
                                  data-slick-setting='{
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "arrows": true,
        "autoplay": false,
        "autoplaySpeed": 5000,
        "speed": 500,
        "asNavFor": ".product-details-big-image-slider-wrapper",
        "focusOnSelect": true,
        "centerMode": false,
        "prevArrow": {"buttonClass": "slick-prev", "iconClass": "fa fa-angle-left" },
        "nextArrow": {"buttonClass": "slick-next", "iconClass": "fa fa-angle-right" }
    }'
                                  data-slick-responsive='[
        {"breakpoint":1501, "settings": {"slidesToShow": 3, "arrows": false} },
        {"breakpoint":1199, "settings": {"slidesToShow": 3, "arrows": false} },
        {"breakpoint":991, "settings": {"slidesToShow": 5, "arrows": false, "slidesToScroll": 1} },
        {"breakpoint":767, "settings": {"slidesToShow": 3, "arrows": false, "slidesToScroll": 1} },
        {"breakpoint":575, "settings": {"slidesToShow": 3, "arrows": false, "slidesToScroll": 1} },
        {"breakpoint":479, "settings": {"slidesToShow": 2, "arrows": false, "slidesToScroll": 1} }
    ]'
                                >
                                  <div className="single-image thumb-image">
                                    <img
                                      src={product.defaultImage}
                                      className=""
                                      alt=""
                                    />
                                  </div>

                                  {product.images.map((img, indx) => {
                                    return (
                                      <div
                                        key={indx}
                                        className="single-image thumb-image"
                                      >
                                        <img
                                          src={img.url}
                                          className=""
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>

                              {/* Mobile */}
                              <div className="product-details-slider-area product-details-slider-area-mobile">
                                <div className="big-image-wrapper">
                                  <div
                                    className="product-details-big-image-slider-wrapper product-details-big-image-slider-wrapper--bottom-space ht-slick-slider"
                                    data-slick-setting='{
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "arrows": false,
            "autoplay": false,
            "autoplaySpeed": 5000,
            "fade": true,
            "speed": 500,
            "prevArrow": {"buttonClass": "slick-prev", "iconClass": "fa fa-angle-left" },
            "nextArrow": {"buttonClass": "slick-next", "iconClass": "fa fa-angle-right" }
        }'
                                    data-slick-responsive='[
            {"breakpoint":1501, "settings": {"slidesToShow": 1, "arrows": false} },
            {"breakpoint":1199, "settings": {"slidesToShow": 1, "arrows": false} },
            {"breakpoint":991, "settings": {"slidesToShow": 1, "arrows": false, "slidesToScroll": 1} },
            {"breakpoint":767, "settings": {"slidesToShow": 1, "arrows": false, "slidesToScroll": 1} },
            {"breakpoint":575, "settings": {"slidesToShow": 1, "arrows": false, "slidesToScroll": 1} },
            {"breakpoint":479, "settings": {"slidesToShow": 1, "arrows": false, "slidesToScroll": 1} }
        ]'
                                  >
                                    {product.defaultImage ? (
                                      <div className="any-class-name">
                                        <TransformWrapper
                                          wheel={{ disabled: true }}
                                          // panning={{ disabled: true }}
                                          // pinch={{ disabled: true }}
                                        >
                                          {({
                                            zoomIn,
                                            zoomOut,
                                            resetTransform,

                                            ...rest
                                          }) => (
                                            <>
                                              <div className="zoomBtn">
                                                <button
                                                  className="zoomIn"
                                                  onClick={() => zoomIn()}
                                                >
                                                  <i className="fa fa-search-plus"></i>
                                                </button>
                                                <button
                                                  className="zoomOut"
                                                  onClick={() => zoomOut()}
                                                >
                                                  <i className="fa fa-search-minus"></i>
                                                </button>

                                                <button
                                                  className="zoomIn"
                                                  onClick={() =>
                                                    resetTransform()
                                                  }
                                                >
                                                  <i className="fa fa-times"></i>
                                                </button>
                                              </div>
                                              <TransformComponent>
                                                <img
                                                  onClick={() => zoomIn()}
                                                  src={product.defaultImage}
                                                  alt=""
                                                />
                                              </TransformComponent>
                                            </>
                                          )}
                                        </TransformWrapper>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {product.images.map((img, indx) => {
                                      return (
                                        <div
                                          key={indx}
                                          className="any-class-name"
                                        >
                                          {img.url ? (
                                            <TransformWrapper
                                              wheel={{ disabled: true }}
                                            >
                                              {({
                                                zoomIn,
                                                zoomOut,
                                                resetTransform,
                                                ...rest
                                              }) => (
                                                <>
                                                  <div className="zoomBtn">
                                                    <button
                                                      className="zoomIn"
                                                      onClick={() => zoomIn()}
                                                    >
                                                      <i className="fa fa-search-plus"></i>
                                                    </button>
                                                    <button
                                                      className="zoomOut"
                                                      onClick={() => zoomOut()}
                                                    >
                                                      <i className="fa fa-search-minus"></i>
                                                    </button>

                                                    <button
                                                      className="zoomIn"
                                                      onClick={() =>
                                                        resetTransform()
                                                      }
                                                    >
                                                      <i className="fa fa-times"></i>
                                                    </button>
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      onClick={() => zoomIn()}
                                                      src={img.url}
                                                      alt=""
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>

                                <div
                                  className="product-details-small-image-slider-wrapper product-details-small-image-slider-wrapper--horizontal-space ht-slick-slider"
                                  data-slick-setting='{
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "arrows": true,
        "autoplay": false,
        "autoplaySpeed": 5000,
        "speed": 500,
        "asNavFor": ".product-details-big-image-slider-wrapper",
        "focusOnSelect": true,
        "centerMode": false,
        "prevArrow": {"buttonClass": "slick-prev", "iconClass": "fa fa-angle-left" },
        "nextArrow": {"buttonClass": "slick-next", "iconClass": "fa fa-angle-right" }
    }'
                                  data-slick-responsive='[
        {"breakpoint":1501, "settings": {"slidesToShow": 3, "arrows": false} },
        {"breakpoint":1199, "settings": {"slidesToShow": 3, "arrows": false} },
        {"breakpoint":991, "settings": {"slidesToShow": 5, "arrows": false, "slidesToScroll": 1} },
        {"breakpoint":767, "settings": {"slidesToShow": 3, "arrows": false, "slidesToScroll": 1} },
        {"breakpoint":575, "settings": {"slidesToShow": 3, "arrows": false, "slidesToScroll": 1} },
        {"breakpoint":479, "settings": {"slidesToShow": 2, "arrows": false, "slidesToScroll": 1} }
    ]'
                                >
                                  <div className="single-image thumb-image">
                                    <img
                                      src={product.defaultImage}
                                      className=""
                                      alt=""
                                    />
                                  </div>

                                  {product.images.map((img, indx) => {
                                    return (
                                      <div
                                        key={indx}
                                        className="single-image thumb-image"
                                      >
                                        <img
                                          src={img.url}
                                          className=""
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              {/*=======  End of product details slider area  =======*/}
                            </div>
                            <div className="col-lg-6">
                              {/*=======  single product content description  =======*/}
                              <div className="single-product-content-description">
                                <p className="single-info">
                                  Brands{" "}
                                  <Link to="#">{product.brand.name}</Link>
                                </p>
                                <h4 className="product-title">
                                  {product.name}
                                </h4>
                                <div className="product-rating">
                                  <span className="rating">
                                    <Rating
                                      readonly
                                      emptySymbol="fa fa-star-o fa-1x"
                                      fullSymbol="fa fa-star fa-1x text-danger"
                                      initialRating={avarageRating}
                                    />
                                  </span>

                                  <span className="review-count">
                                    {" "}
                                    <a href="#">
                                      ({product.reviews.length} reviews)
                                    </a>{" "}
                                    |{" "}
                                    <a href="#" onClick={scrollToReview}>
                                      Write A Review
                                    </a>{" "}
                                  </span>
                                </div>

                                <p className="single-grid-product__price">
                                  <span className="discounted-price">
                                    <i className="fa fa-inr"></i>
                                    {product.sellingPrice}
                                  </span>{" "}
                                  <span className="main-price discounted">
                                    <i className="fa fa-inr"></i>
                                    {product.mrp}
                                  </span>{" "}
                                  <span className="single-info px-2">
                                    (Inclusive of all taxes)
                                  </span>
                                </p>

                                <div className="row">
                                  {/* General Information */}
                                  <div className="col-md-6">
                                    {/* Product Code */}
                                    {product.code && (
                                      <p className="single-info">
                                        Product Code :{" "}
                                        <span className="value">
                                          {product.code}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Capacity */}
                                    {product.capacity && (
                                      <p className="single-info">
                                        Capacity :{" "}
                                        <span className="value">
                                          {product.capacity}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product packageContent */}
                                    {product.packageContent && (
                                      <p className="single-info">
                                        Package Content :{" "}
                                        <span className="value">
                                          {product.packageContent}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product pileHeight */}
                                    {product.pileHeight && (
                                      <p className="single-info">
                                        Pile Height :{" "}
                                        <span className="value">
                                          {product.pileHeight}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product mountingType */}
                                    {product.mountingType && (
                                      <p className="single-info">
                                        Mounting Type :{" "}
                                        <span className="value">
                                          {product.mountingType}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product care */}
                                    {product.care && (
                                      <p className="single-info">
                                        Care :{" "}
                                        <span className="value">
                                          {product.care}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Color */}
                                    {product.color && (
                                      <p className="single-info">
                                        Color :{" "}
                                        <span className="value">
                                          {product.color}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product make */}
                                    {product.make && (
                                      <p className="single-info">
                                        Make :{" "}
                                        <span className="value">
                                          {product.make}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product shape */}
                                    {product.shape && (
                                      <p className="single-info">
                                        Shape :{" "}
                                        <span className="value">
                                          {product.shape}
                                        </span>
                                      </p>
                                    )}

                                    <p className="single-info">
                                      Material :{" "}
                                      <span className="value">
                                        {product.material.name}
                                      </span>
                                    </p>
                                  </div>

                                  {/* Dimension  */}
                                  <div className="col-md-6">
                                    {/* <h5>Dimensions</h5> */}
                                    {product.weight && (
                                      <p className="single-info">
                                        Weight :{" "}
                                        <span className="value">
                                          {product.weight}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Length */}
                                    {product["length"] && (
                                      <p className="single-info">
                                        Length :{" "}
                                        <span className="value">
                                          {product["length"]}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Width */}
                                    {product.width && (
                                      <p className="single-info">
                                        Width :{" "}
                                        <span className="value">
                                          {product.width}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Height */}
                                    {product.height && (
                                      <p className="single-info">
                                        Height :{" "}
                                        <span className="value">
                                          {product.height}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Diameter */}
                                    {product.diameter && (
                                      <p className="single-info">
                                        Diameter :{" "}
                                        <span className="value">
                                          {product.diameter}
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <p
                                  className="product-description"
                                  style={{
                                    margin: "15px 0",
                                    padding: "0",
                                  }}
                                ></p>

                                {/* Bullets Points */}
                                <ul className="list-group pb-3">
                                  {product.bulletPoints.map((item, _i) => {
                                    return (
                                      <li className="list-group-item" key={_i}>
                                        {item}
                                      </li>
                                    );
                                  })}
                                </ul>

                                {/* <div
                                  style={{ display: "none" }}
                                  className="product-countdown"
                                  data-countdown="2020/06/01"
                                ></div> */}

                                {/* Stock are available or not */}
                                {product.stock ? (
                                  <div className="product-actions p-0 m-0">
                                    <div className="quantity-selection d-flex justify-content-center p-0 m-0">
                                      <button
                                        className="btn btn-dark"
                                        type="button"
                                        onClick={(evt) => {
                                          evt.preventDefault();
                                          setQuantity((old) => {
                                            if (old <= 1) {
                                              toast.warning(
                                                "Minimum quantity must be 1"
                                              );
                                              return 1;
                                            } else {
                                              return old - 1;
                                            }
                                          });
                                        }}
                                      >
                                        -
                                      </button>
                                      <input
                                        type="number"
                                        value={quantity}
                                        min="1"
                                      />
                                      <button
                                        className="btn btn-dark"
                                        type="button"
                                        onClick={(evt) => {
                                          evt.preventDefault();
                                          setQuantity((old) => {
                                            if (
                                              old < product.maxOrderQuantity
                                            ) {
                                              return old + 1;
                                            } else {
                                              toast.warning(
                                                `Allowed only ${product.maxOrderQuantity} Items per User`
                                              );
                                              return old;
                                            }
                                          });
                                        }}
                                      >
                                        +
                                      </button>
                                    </div>

                                    <div className="product-buttons">
                                      {cart.some(
                                        (value) =>
                                          value.productId == product._id
                                      ) ? (
                                        <Link
                                          onClick={() => {
                                            history.push("/cart");
                                          }}
                                          className="cart-btn"
                                          to="#"
                                        >
                                          {" "}
                                          <i className="ion-bag"></i> GO TO CART
                                        </Link>
                                      ) : (
                                        <Link
                                          onClick={() => {
                                            addToCartHandler(
                                              "",
                                              product,
                                              quantity
                                            );
                                          }}
                                          className="cart-btn"
                                          to="#"
                                        >
                                          {" "}
                                          <i className="ion-bag"></i>{" "}
                                          {product.buttonText
                                            ? product.buttonText.toUpperCase()
                                            : "ADD TO CART"}
                                        </Link>
                                      )}

                                      <span className="wishlist-compare-btn">
                                        {myWishlists.some((item) => {
                                          return (
                                            item.product._id == product._id
                                          );
                                        }) ? (
                                          <a
                                            onClick={(evt) => {
                                              const wishList =
                                                myWishlists.filter((item) => {
                                                  return (
                                                    item.product._id ==
                                                    product._id
                                                  );
                                                });
                                              removeWishlistHandler(
                                                evt,
                                                wishList[0]._id
                                              );
                                            }}
                                          >
                                            {removeWishlistLoading ? (
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                style={{
                                                  height: "11px",
                                                  width: "11px",
                                                }}
                                                aria-hidden="true"
                                              ></span>
                                            ) : (
                                              <i className="ion-heart text-danger"></i>
                                            )}
                                          </a>
                                        ) : (
                                          <a onClick={addWishlistHandler}>
                                            {addWishlistLoading ? (
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                style={{
                                                  height: "11px",
                                                  width: "11px",
                                                }}
                                                aria-hidden="true"
                                              ></span>
                                            ) : (
                                              <i className="ion-heart"></i>
                                            )}
                                          </a>
                                        )}
                                        {/* <a>
                                        {" "}
                                        <i className="ion-android-options"></i>
                                      </a> */}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="product-actions p-0 m-0">
                                    <div className="product-buttons">
                                      <Link
                                        onClick={(evt) => {
                                          evt.preventDefault();
                                        }}
                                        className="cart-btn text-danger border-danger"
                                        to="#"
                                      >
                                        {" "}
                                        <i className="ion-bag"></i>{" "}
                                        {"OUT OF STOCK"}
                                      </Link>
                                      <span className="wishlist-compare-btn">
                                        {myWishlists.some((item) => {
                                          return (
                                            item.product._id == product._id
                                          );
                                        }) ? (
                                          <a
                                            onClick={(evt) => {
                                              const wishList =
                                                myWishlists.filter((item) => {
                                                  return (
                                                    item.product._id ==
                                                    product._id
                                                  );
                                                });
                                              removeWishlistHandler(
                                                evt,
                                                wishList[0]._id
                                              );
                                            }}
                                          >
                                            {removeWishlistLoading ? (
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                style={{
                                                  height: "11px",
                                                  width: "11px",
                                                }}
                                                aria-hidden="true"
                                              ></span>
                                            ) : (
                                              <i className="ion-heart text-danger"></i>
                                            )}
                                          </a>
                                        ) : (
                                          <a onClick={addWishlistHandler}>
                                            {addWishlistLoading ? (
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                style={{
                                                  height: "11px",
                                                  width: "11px",
                                                }}
                                                aria-hidden="true"
                                              ></span>
                                            ) : (
                                              <i className="ion-heart"></i>
                                            )}
                                          </a>
                                        )}
                                        {/* <a>
                                        {" "}
                                        <i className="ion-android-options"></i>
                                      </a> */}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="social-share-buttons mt-20">
                                  <h5>share this product</h5>
                                  <ul>
                                    <li>
                                      <TwitterSharingButton />
                                      {/* <a className="twitter" href="#">
                                    <i className="fa fa-twitter"></i>
                                  </a> */}
                                    </li>
                                    <li>
                                      <FacebookSharingButton />
                                      {/* <a className="facebook" href="#">
                                    <i className="fa fa-facebook"></i>
                                  </a> */}
                                    </li>
                                    {/* <li>
                                  <a className="google-plus" href="#">
                                    <i className="fa fa-google-plus"></i>
                                  </a>
                                </li> */}
                                    <li>
                                      <WhatsappSharingButton />
                                      {/* <a className="pinterest" href="#">
                                    <i className="fa fa-pinterest"></i>
                                  </a> */}
                                    </li>
                                  </ul>
                                </div>
                                {/* <p className="single-info mb-0">
                              Tags: <a href="shop-left-sidebar.html">Dolor</a>,{" "}
                              <a href="shop-left-sidebar.html">Ipsum</a>,{" "}
                              <a href="shop-left-sidebar.html">Lorem</a>{" "}
                            </p> */}
                              </div>
                              {/*=======  End of single product content description  =======*/}
                            </div>
                          </div>
                        ) : (
                          <PageNotFound text="Page Not Found !!!" />
                        )}
                      </>
                    )}
                  </div>
                  {/*=======  End of single product main content area  =======*/}
                  {/*=======  product description review   =======*/}

                  <div
                    className="product-description-review-area"
                    ref={reviewRef}
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        {/*=======  product description review container  =======*/}

                        <div className="tab-slider-wrapper product-description-review-container  section-space--inner">
                          <nav>
                            <div
                              className="nav nav-tabs justify-content-center"
                              id="nav-tab"
                              role="tablist"
                            >
                              <a
                                className="nav-item nav-link active"
                                id="description-tab"
                                data-bs-toggle="tab"
                                href="#product-description"
                                role="tab"
                                aria-selected="true"
                              >
                                Description
                              </a>
                              <a
                                className="nav-item nav-link"
                                id="review-tab"
                                data-bs-toggle="tab"
                                href="#review"
                                role="tab"
                                aria-selected="false"
                              >
                                Reviews ({product.reviews.length})
                              </a>
                            </div>
                          </nav>
                          <div className="tab-content" id="nav-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="product-description"
                              role="tabpanel"
                              aria-labelledby="description-tab"
                            >
                              {/*=======  product description  =======*/}

                              <div className="product-descriptio">
                                <div className="single-product-content-description ">
                                  <h4>General Information</h4>
                                  <div style={{ paddingLeft: "10px" }}>
                                    {/* Product Code */}
                                    {product.code && (
                                      <p className="single-info">
                                        Product Code :{" "}
                                        <span className="value">
                                          {product.code}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Capacity */}
                                    {product.capacity && (
                                      <p className="single-info">
                                        Capacity :{" "}
                                        <span className="value">
                                          {product.capacity}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product packageContent */}
                                    {product.packageContent && (
                                      <p className="single-info">
                                        Package Content :{" "}
                                        <span className="value">
                                          {product.packageContent}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product pileHeight */}
                                    {product.pileHeight && (
                                      <p className="single-info">
                                        Pile Height :{" "}
                                        <span className="value">
                                          {product.pileHeight}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product mountingType */}
                                    {product.mountingType && (
                                      <p className="single-info">
                                        Mounting Type :{" "}
                                        <span className="value">
                                          {product.mountingType}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product care */}
                                    {product.care && (
                                      <p className="single-info">
                                        Care :{" "}
                                        <span className="value">
                                          {product.care}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Color */}
                                    {product.color && (
                                      <p className="single-info">
                                        Color :{" "}
                                        <span className="value">
                                          {product.color}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product make */}
                                    {product.make && (
                                      <p className="single-info">
                                        Make :{" "}
                                        <span className="value">
                                          {product.make}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product shape */}
                                    {product.shape && (
                                      <p className="single-info">
                                        Shape :{" "}
                                        <span className="value">
                                          {product.shape}
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="single-product-content-description">
                                  <h4>Dimensions</h4>
                                  <div style={{ paddingLeft: "10px" }}>
                                    {/* Product Weight */}
                                    {product.weight && (
                                      <p className="single-info">
                                        Weight :{" "}
                                        <span className="value">
                                          {product.weight}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Length */}
                                    {product["length"] && (
                                      <p className="single-info">
                                        Length :{" "}
                                        <span className="value">
                                          {product["length"]}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Width */}
                                    {product.width && (
                                      <p className="single-info">
                                        Width :{" "}
                                        <span className="value">
                                          {product.width}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Height */}
                                    {product.height && (
                                      <p className="single-info">
                                        Height :{" "}
                                        <span className="value">
                                          {product.height}
                                        </span>
                                      </p>
                                    )}

                                    {/* Product Diameter */}
                                    {product.diameter && (
                                      <p className="single-info">
                                        Diameter :{" "}
                                        <span className="value">
                                          {product.diameter}
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="single-product-content-description">
                                  {/* Shipping Information */}

                                  <h4>Return Condition</h4>
                                  <div style={{ paddingLeft: "10px" }}>
                                    <p className="single-info">
                                      Product Returnable :{" "}
                                      <span className="value">
                                        {product.isReturnable ? "Yes" : "No"}
                                      </span>
                                    </p>

                                    {/* Return Condition */}
                                    {product.isReturnable && (
                                      <p className="single-info">
                                        Return Condition :{" "}
                                        <span className="value">
                                          {product.returnCondition} Days
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="">
                                  <h4 className="p-0 m-0">
                                    Product Descriptions
                                  </h4>

                                  <div style={{ paddingLeft: "10px" }}>
                                    {product.description
                                      ? parse(product.description)
                                      : ""}
                                  </div>
                                </div>
                              </div>

                              {/*=======  End of product description  =======*/}
                            </div>

                            <div
                              className="tab-pane fade"
                              id="review"
                              role="tabpanel"
                              aria-labelledby="review-tab"
                            >
                              {/*=======  review content  =======*/}

                              <div className="product-rating-wrap">
                                <div className="pro-avg-rating">
                                  <h4>
                                    {avarageRating} <span>(Overall)</span>
                                  </h4>
                                  <span>
                                    Based on {product.reviews.length} Comments
                                  </span>
                                </div>
                                <div className="rating-list">
                                  <div className="sin-list float-start">
                                    <Rating
                                      readonly
                                      emptySymbol="fa fa-star-o fa-1x"
                                      fullSymbol="fa fa-star fa-1x text-dard"
                                      initialRating={5}
                                    />
                                    <span>({reviewStars.five})</span>
                                  </div>
                                  <div className="sin-list float-start">
                                    <Rating
                                      readonly
                                      emptySymbol="fa fa-star-o fa-1x"
                                      fullSymbol="fa fa-star fa-1x text-dark"
                                      initialRating={4}
                                    />
                                    <span>({reviewStars.four})</span>
                                  </div>
                                  <div className="sin-list float-start">
                                    <Rating
                                      readonly
                                      emptySymbol="fa fa-star-o fa-1x"
                                      fullSymbol="fa fa-star fa-1x text-dark"
                                      initialRating={3}
                                    />
                                    <span>({reviewStars.three})</span>
                                  </div>
                                  <div className="sin-list float-start">
                                    <Rating
                                      readonly
                                      emptySymbol="fa fa-star-o fa-1x"
                                      fullSymbol="fa fa-star fa-1x text-dark"
                                      initialRating={2}
                                    />
                                    <span>({reviewStars.two})</span>
                                  </div>
                                  <div className="sin-list float-start">
                                    <Rating
                                      readonly
                                      emptySymbol="fa fa-star-o fa-1x"
                                      fullSymbol="fa fa-star fa-1x text-dark"
                                      initialRating={1}
                                    />
                                    <span>({reviewStars.onw})</span>
                                  </div>
                                </div>
                                <div className="ratings-wrapper">
                                  {product.reviews.map((item, index) => {
                                    return (
                                      <div className="sin-ratings" key={index}>
                                        <div className="rating-author">
                                          <h3>
                                            {item.customer
                                              ? item.customer.firstName
                                              : "Guest"}
                                          </h3>
                                          <div className="">
                                            <Rating
                                              readonly
                                              emptySymbol="fa fa-star-o fa-1x"
                                              fullSymbol="fa fa-star fa-1x text-danger"
                                              initialRating={item.rating}
                                            />
                                            <span>({item.rating})</span>
                                          </div>
                                        </div>
                                        <p>{item.message}</p>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="rating-form-wrapper fix">
                                  <h3>Add your Comments</h3>
                                  <form action="#" onSubmit={addReviewHandler}>
                                    <div className="rating-form row">
                                      <div className="col-12 mb-15">
                                        <h5>Rating:</h5>
                                        <div className="rating-star fix">
                                          <Rating
                                            onChange={(value) => {
                                              setReview({
                                                ...review,
                                                rating: value,
                                              });
                                            }}
                                            emptySymbol="fa fa-star-o fa-1x"
                                            fullSymbol="fa fa-star fa-1x text-danger"
                                            initialRating={review.rating}
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="col-md-6 col-12 form-group">
                                        <label for="name">Name:</label>
                                        <input
                                          id="name"
                                          placeholder="Name"
                                          type="text"
                                        />
                                      </div> */}
                                      {/* <div className="col-md-6 col-12 form-group">
                                        <label for="email">Email:</label>
                                        <input
                                          id="email"
                                          placeholder="Email"
                                          type="text"
                                        />
                                      </div> */}
                                      <div className="col-12 form-group">
                                        <label htmlFor="your-review">
                                          Your Review:
                                        </label>
                                        <textarea
                                          name="review"
                                          id="your-review"
                                          value={review.message}
                                          onChange={(evt) => {
                                            setReview({
                                              ...review,
                                              message: evt.target.value,
                                            });
                                          }}
                                          placeholder="Write a review"
                                        ></textarea>
                                      </div>
                                      <div className="col-12">
                                        <button className="submit-btn">
                                          {addReviewLoading ? (
                                            <Loader />
                                          ) : (
                                            "ADD REVIEW"
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>

                              {/*=======  End of review content  =======*/}
                            </div>
                          </div>
                        </div>

                        {/*=======  End of product description review container  =======*/}
                      </div>
                    </div>
                  </div>

                  {/*=======  End of product description review   =======*/}
                  {/*====================  single row slider ====================*/}
                  <div className="single-row-slider-area section-space--inner-top">
                    <div className="row">
                      <div className="col-lg-12">
                        {/*=======  section title  =======*/}
                        <div className="section-title-wrapper text-center section-space--half">
                          <h2 className="section-title">Related Products</h2>
                          <p className="section-subtitle">
                            Here you can get the related products.
                          </p>
                        </div>
                        {/*=======  End of section title  =======*/}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        {/*=======  single row slider wrapper  =======*/}
                        <div
                        // className="single-row-slider-wrapper"
                        >
                          <div className="ht-slick-slider">
                            {relatedProduct.length ? (
                              <Slider {...sliderSettings}>
                                {relatedProduct.map((product, pIndex) => {
                                  // Discound Presentage
                                  const discountPercent = Math.ceil(
                                    100 -
                                      (Number.parseInt(product.sellingPrice) *
                                        100) /
                                        Number.parseInt(product.mrp)
                                  );

                                  // Calculate avarage reviews
                                  let avarageRating = 0;
                                  const sumOfRatings = product.reviews.reduce(
                                    (accumulator, current) => {
                                      return accumulator + current.rating;
                                    },
                                    0
                                  );
                                  avarageRating = (
                                    sumOfRatings / product.reviews.length
                                  ).toFixed(1);

                                  // Check Item in available in the cart or not
                                  let availableInCart = false;

                                  if (state && state.cart.length) {
                                    let available = state.cart.some((item) => {
                                      return item.productId == product._id;
                                    });
                                    if (available) availableInCart = true;
                                  }

                                  // Check Item in available in the wishlist or not
                                  let availableInWishlist = false;

                                  let available = myWishlists.some((item) => {
                                    return item.product._id == product._id;
                                  });
                                  if (available) availableInWishlist = true;
                                  // if (myWishlists.length) {
                                  // }

                                  return (
                                    <div
                                      className="col-md-3"
                                      key={`p-${pIndex}`}
                                    >
                                      {/*-=======  single grid product  =======*/}
                                      <ProductCard
                                        availableInCart={availableInCart}
                                        availableInWishlist={
                                          availableInWishlist
                                        }
                                        product={product}
                                        removeWishlistHandler={
                                          removeWishlistHandler
                                        }
                                        category={product.parentCategory}
                                        avarageRating={avarageRating}
                                        addToCartHandler={addToCartHandler}
                                        addWishlistHandler={addWishlistHandler}
                                        addWishlistLoading={addWishlistLoading}
                                        myWishlists={myWishlists}
                                        removeWishlistLoading={
                                          removeWishlistLoading
                                        }
                                        discountPercent={discountPercent}
                                      />
                                      {/*-=======  End of single grid product  =======*/}
                                    </div>
                                  );
                                })}
                              </Slider>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/*=======  End of single row slider wrapper  =======*/}
                      </div>
                    </div>
                  </div>
                  {/*====================  End of single row slider  ====================*/}
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default ProductDetails;
